import React, { useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { getURLPathWithQueryParams, isEmpty } from '@/common/helpers';

import { useBackupsStatusMessage } from './backups-messsage';
import LoadingModal from '../loading-modal';
import ProductPivot, { statuses } from './product-pivot';
import { events } from '../track';

const BackupsStatus = (props) => {
  const { backups, loading, domainData, latestBackup, latestBackupLoading } =
    props;

  const isLoading = loading || latestBackupLoading;
  const message = useBackupsStatusMessage({
    backups,
    latestBackup,
    latestBackupLoading,
    loading,
  });
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const router = useRouter();
  const intl = useIntl();

  if (router && router.beforePopState) {
    router.beforePopState(() => {
      setShowLoadingModal(false);
      return true;
    });
  }

  const handleClick = () => {
    // Avoids sending  users to /site/undefined/backups.
    if (loading) return;

    const backupId = domainData.backups.id;

    if (isEmpty(latestBackup)) {
      router.push(
        getURLPathWithQueryParams(`/site/${backupId}/backups?action=setup`),
      );
      return;
    }

    router.push('/site/[id]/backups', `/site/${backupId}/backups`);
  };

  return (
    <>
      {showLoadingModal && (
        <LoadingModal
          onClose={() => {
            setShowLoadingModal(false);
            setLoadingError(null);
          }}
          productName={intl.formatMessage({ id: 'common__backups' })}
          maxSeconds={8}
          error={loadingError}
        />
      )}
      <ProductPivot
        product="backups"
        status={isLoading ? statuses.LOADING : message.status}
        statusTextID={message.description}
        onClick={handleClick}
        rating={domainData.letterScore}
        customEvent={events.MYSITES_SITECARD_BACKUPS_CLICK}
        hideButton={!domainData?.backups?.id}
      />
    </>
  );
};

BackupsStatus.propTypes = {
  domain: PropTypes.string.isRequired,
  backups: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  domainData: PropTypes.object.isRequired,
  latestBackup: PropTypes.object,
  latestBackupLoading: PropTypes.bool,
  isBackupsInternalMgmtOn: PropTypes.bool,
};

export default BackupsStatus;
